import { useCallback, useMemo } from 'react'
import s from './SendNotificationContent.scss'
import AsyncCreatableSelect from 'react-select/async-creatable'
import { components } from 'react-select'
import { makeGetRequest } from 'utils/api'
import debounce from 'debounce-promise'
import { format, parseISO } from 'date-fns'
import { emailRateRow } from 'timekeepers/tableDefinitions'
import { NotifyData, NotifyUser, NotifyTimekeeperData } from 'timekeepers/types'
import { FlatTable } from 'timekeepers/common'
import { useDispatch } from 'react-redux'

const MultiValueLabel = (props: any) => {
  return (
    <components.MultiValueLabel {...props}>
      <section>
        {!props.data.email ? (
          <span className={s.multiPrimaryLabel}>{props.data.label}</span>
        ) : (
          <>
            <span className={s.multiPrimaryLabel}>{props.data.fullName}</span>
            <span className="">{props.data.email}</span>
          </>
        )}
      </section>
    </components.MultiValueLabel>
  )
}
interface Props {
  vendorId: string
  user: NotifyUser[]
  setUser: (value: NotifyUser[]) => void
  notifyData: NotifyData
}

const COLUMNS = [
  { key: 'status', title: 'Status', sortable: false },
  { key: 'requested_rate', title: 'Billed Rate', sortable: false },
  { key: 'rack_rate', title: 'Rack Rate', sortable: false },
  { key: 'currency', title: 'Currency', sortable: false },
  { key: 'effective_date', title: 'Effective Date', sortable: false },
  { key: 'type', title: 'Type', sortable: false }
]

const SendNotificationContent = ({ user, vendorId, setUser, notifyData }: Props) => {
  const today = new Date()
  const dispatch = useDispatch()

  const loadOptions = useCallback(
    async (search: string) => {
      try {
        const response = await makeGetRequest('/user/my/contacts/', {
          params: { vendor: vendorId, term: search }
        })

        return response.map((user: string) => {
          const emailMatch = user.match(/<(.*?)>/)
          const email = emailMatch ? emailMatch[1] : ''
          const nameMatch = user.match(/^[a-zA-Z ]+/)
          const fullName = nameMatch ? nameMatch[0] : ''
          return {
            email,
            fullName,
            label: (
              <p>
                <b>{fullName}</b> {email}
              </p>
            ),
            value: email
          }
        })
      } catch (error) {
        dispatch({
          type: 'API_ERROR',
          error
        })
      }
    },
    [vendorId]
  )
  const debouncedLoadOptions = debounce(loadOptions, 250, {
    leading: false
  })

  const renderTkData = useMemo(() => {
    return notifyData?.tks?.map((tk: NotifyTimekeeperData) => {
      return (
        <div className={s.tkDetail} key={tk.rates[0].id}>
          {tk.is_new_tk ? <span className={s.badge}>New</span> : null}
          <span style={{ fontSize: '16px', padding: '4px' }}>{tk.timekeeper_name}</span>
          <div>
            ID: {tk.timekeeper_id} &#xB7; Classification: {tk.classification} &#xB7; Rates:{' '}
            {tk.rates.length}
          </div>
          <FlatTable
            className={s.tableContainer}
            columns={COLUMNS}
            rows={emailRateRow(tk.rates)}
            handleApprove={async () => {}}
            handleReject={() => {}}
            handleReset={async () => {}}
            renderActionAsStatus
          />
        </div>
      )
    })
  }, [notifyData])

  return (
    <div>
      <div className={s.row}>
        <span className={s.rowLabel}>To</span>
        <span className={s.rowText}>
          <AsyncCreatableSelect
            key={vendorId ?? 0}
            isMulti
            placeholder="Select or add email..."
            defaultOptions
            loadOptions={debouncedLoadOptions}
            onChange={(newValue: any) => {
              setUser(newValue)
            }}
            value={user}
            components={{ MultiValueLabel }}
          />
        </span>
      </div>
      <div className={s.row}>
        <span style={{ width: '13%' }}>Subject</span>
        <span>Timekeeper Rate Review</span>
      </div>
      <div className={s.previewContainer}>
        <img
          style={{ height: '50px' }}
          src={'/static/assets/img/simplelegal-an-onit-company@2x.png'}
        />
        <div className={s.headerText}>
          Review summary for batch submitted{' '}
          {format(parseISO(notifyData.created_date ?? ''), 'MMM dd, yyyy')}
        </div>
        <div className={s.emailBody}>
          <div className={s.vendorName}>
            Hi <span style={{ fontWeight: '700' }}>{notifyData.vendor_name},</span>
          </div>
          <div>
            {notifyData.client_name}, has reviewed your submitted timekeeper file. The results are
            below:
          </div>
          <div className={s.tkCount}>
            <p>Timekeepers Submitted: {notifyData.total_tks}</p>
            <p>Rates Approved: {notifyData.approved_tks}</p>
            <p>Rates Rejected: {notifyData.rejected_tks}</p>
            <p>Rates Pending: {notifyData.other_tks}</p>
          </div>
          {renderTkData}
        </div>
        <div style={{ color: '#767676', textAlign: 'center' }}>
          <p>This is an automated message. Please do not reply to this message. </p>
          <p>
            {' '}
            If you have any questions, contact our support team by emailing&nbsp;
            <a
              href="mailto:help@simplelegal.com"
              style={{ textDecoration: 'auto', color: '#0c56ae' }}
            >
              help@simplelegal.com
            </a>
            .
          </p>
          &copy; {today.getFullYear()}
          <a
            href="http://www.simplelegal.com"
            title="SimpleLegal.com"
            style={{ textDecoration: 'auto', color: '#0c56ae' }}
          >
            SimpleLegal
          </a>
        </div>
      </div>
    </div>
  )
}

export default SendNotificationContent
