import { useAuth } from 'context/AuthContext'
import { HiSparkles } from 'react-icons/hi2'
import { ReactAIChat, ReactAIChatProvider } from 'simple-core-ui'
import s from './AIChatContainer.scss'

function AskAIContainer() {
  const { user } = useAuth()
  return (
    <div>
      <div className={s.header}>
        <span style={{ fontSize: '18px', fontWeight: 700 }}>
          AskAI{' '}
          <HiSparkles
            style={{ width: '20px', position: 'relative', top: '3px', color: '#7C248E' }}
          />
        </span>
      </div>

      <ReactAIChatProvider
        initialState={{
          data: {
            urls: {
              examplePrompts: '/help/?article_id=26560823148183',
              chatDetail: '/reports/chats/<id>/',
              chatList: '/reports/chats/',
              submitMessage: '/reports/chats/',
              createChat: '/reports/chats/',
              deleteChat: '/reports/chats/<id>',
              updateChat: '/reports/chats/<id>/'
            },
            user: {
              firstName: user!.firstName,
              lastName: user!.lastName
            },
            showChatTranscript: user!.isCSM
          }
        }}
      >
        <ReactAIChat />
      </ReactAIChatProvider>
    </div>
  )
}

export default AskAIContainer
