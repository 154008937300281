import { ReactNode, useEffect, useMemo, useState } from 'react'
import s from './FlatTable.scss'
import cn from 'classnames'
import { TimekeeperProfileDetailsRatesAPI, TimekeeperRow } from 'timekeepers/types'
import { StatusPopover } from '../StatusPopover'
import { ChatHover } from 'simple-core-ui'
import ReactTooltip from 'react-tooltip'
import { FaArrowUp, FaArrowDown } from 'react-icons/fa6'
import { SortIcons } from './SortIcons'
import { formatDate } from 'timekeepers/helpers'

interface Column {
  key: string
  title: string | ((col: Column) => ReactNode) | ReactNode
  sortable: boolean
  render?: (row: TimekeeperProfileDetailsRatesAPI, rowIndex: number) => ReactNode
}

interface FlatTableProps {
  columns: Column[]
  rows: TimekeeperProfileDetailsRatesAPI[]
  className?: string
  tableContainerClassName?: string
  title?: string
  actions?: ReactNode
  hasBorder?: boolean
  handleApprove?: (row?: TimekeeperRow, all?: boolean) => Promise<void>
  handleReject?: (row?: TimekeeperRow, all?: boolean) => void
  handleReset?: (row: TimekeeperRow) => Promise<void>
  renderActionAsStatus?: boolean
}

const renderPercentage = (value: string) => {
  if (!value) return '---'
  const isNegative = value.toString().startsWith('-')
  const displayValue = isNegative ? value.toString().slice(1) : value
  return (
    <>
      {isNegative ? <FaArrowDown className={s.arrowDown} /> : <FaArrowUp className={s.arrowUp} />}
      {displayValue}%
    </>
  )
}

const FlatTable = ({
  columns,
  rows,
  className,
  tableContainerClassName,
  title,
  actions,
  hasBorder,
  handleApprove,
  handleReject,
  handleReset,
  renderActionAsStatus
}: FlatTableProps) => {
  useEffect(() => {
    ReactTooltip.rebuild()
  }, [])
  const [sortConfig, setSortConfig] = useState<{ key: string; direction: 'asc' | 'desc' } | null>(
    null
  )

  const sortedRows = useMemo(() => {
    if (!sortConfig) return rows
    const sorted = [...rows].sort((a, b) => {
      const aValue = a[sortConfig.key as keyof TimekeeperProfileDetailsRatesAPI] as string | number
      const bValue = b[sortConfig.key as keyof TimekeeperProfileDetailsRatesAPI] as string | number

      if (typeof aValue === 'string' && typeof bValue === 'string') {
        const aValueLower = aValue.toLowerCase()
        const bValueLower = bValue.toLowerCase()
        if (aValueLower < bValueLower) return sortConfig.direction === 'asc' ? -1 : 1
        if (aValueLower > bValueLower) return sortConfig.direction === 'asc' ? 1 : -1
        return 0
      }

      if (aValue < bValue) return sortConfig.direction === 'asc' ? -1 : 1
      if (aValue > bValue) return sortConfig.direction === 'asc' ? 1 : -1
      return 0
    })
    return sorted
  }, [rows, sortConfig])

  const handleSort = (key: string) => {
    let direction: 'asc' | 'desc' = 'asc'
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc'
    }
    setSortConfig({ key, direction })
  }

  return (
    <div className={cn(s.container, tableContainerClassName)}>
      <div className={s.header}>
        {title ? <p className={s.tableTitle}>{title}</p> : null}
        {actions ? <div className={s.tableActions}>{actions}</div> : null}
      </div>
      <div className={cn(s.tableContainer, { [s.tableBorder]: hasBorder })}>
        <table className={s.table}>
          <thead>
            <tr>
              {columns.map(col => (
                <th
                  className={cn({
                    [s.highlightColumn]: col.key === sortConfig?.key,
                    [s.sortable]: col.sortable,
                    [s.alignRight]: col.key === 'actions'
                  })}
                  onClick={() => col.sortable && handleSort(col.key)}
                  key={col.key}
                >
                  {col.sortable && (
                    <SortIcons
                      isDesc={
                        col.key === sortConfig?.key ? sortConfig?.direction === 'desc' : undefined
                      }
                    />
                  )}
                  {typeof col.title === 'function' ? col.title(col) : col.title}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className={s.tableBody}>
            {sortedRows.map((row, rowIndex) => (
              <>
                <tr key={rowIndex}>
                  {columns.map(col => {
                    const cellContent = row[col.key as keyof TimekeeperProfileDetailsRatesAPI]
                    const isBoldText = ['requested_rate', 'rack_rate'].includes(col.key)
                    const isSpecialColumn = [
                      'actions',
                      'change_percentage',
                      'class_comparison',
                      'effective',
                      'reason_for_increase',
                      'status',
                      'type'
                    ].includes(col.key)

                    if (!isSpecialColumn) {
                      return (
                        <td className={cn({ [s.boldText]: isBoldText })} key={col.key}>
                          {cellContent || '---'}
                        </td>
                      )
                    }

                    switch (col.key) {
                      case 'reason_for_increase':
                        return (
                          <td key={col.key}>
                            <div className={s.chatBubble}>
                              {cellContent ? <ChatHover text={cellContent as string} /> : '---'}
                            </div>
                          </td>
                        )
                      case 'class_comparison':
                      case 'change_percentage':
                        return <td key={col.key}>{renderPercentage(cellContent as string)}</td>
                      case 'status':
                        return (
                          <td key={col.key}>
                            {renderActionAsStatus ? (
                              <StatusPopover
                                mode={'dropdown'}
                                status={row.status}
                                handleApprove={() => Promise.resolve()}
                                handleReject={() => Promise.resolve()}
                                handleReset={() => Promise.resolve()}
                                tableRow={row}
                                readOnly
                              />
                            ) : cellContent === 'approve' ? (
                              'Approved'
                            ) : cellContent === 'reject' ? (
                              'Rejected'
                            ) : (
                              '---'
                            )}
                          </td>
                        )
                      case 'effective':
                        return (
                          <td key={col.key}>
                            {formatDate(cellContent as string, 'MM/dd/yyyy') || '---'}
                          </td>
                        )
                      case 'type':
                        return <td key={col.key}>{cellContent || 'Standard'}</td>
                      case 'actions':
                        return (
                          <td key={col.key}>
                            <StatusPopover
                              mode={row.status === 'pending' ? 'buttons' : 'dropdown'}
                              status={row.status}
                              handleApprove={
                                handleApprove as (
                                  row?: TimekeeperRow,
                                  all?: boolean
                                ) => Promise<void>
                              }
                              handleReject={
                                handleReject as (row?: TimekeeperRow, all?: boolean) => void
                              }
                              handleReset={handleReset as (row: TimekeeperRow) => Promise<void>}
                              tableRow={row}
                            />
                          </td>
                        )
                      default:
                        return null
                    }
                  })}
                </tr>
                {row.status_reason ? (
                  <tr>
                    <td className={s.rejectionReason}>Rejection Reason</td>
                    <td colSpan={5}>{row.status_reason}</td>
                  </tr>
                ) : null}
              </>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  )
}

export default FlatTable
