import { FlatTable, InfoCard } from 'timekeepers/common'
import {
  EXISTING_TK_HISTORICAL_RATES_COLUMNS,
  EXISTING_TK_PENDING_RATES_COLUMNS,
  NEW_TIMEKEEPER_HISTORY_COLUMNS,
  NEW_TK_PENDING_RATES_COLUMNS
} from '../tableDefinitions'
import {
  DiversityKeyValuePairs,
  KeyValuePairs,
  TimekeeperProfileDetailsRatesAPI,
  TimekeeperProfileInfo,
  TimekeeperRow
} from 'timekeepers/types'
import s from './Rates.scss'
import pluralize from 'pluralize'
import { formatAttribute } from 'timekeepers/helpers'
import { FaArrowRight } from 'react-icons/fa'

interface Props {
  isNewTk?: boolean
  actionButtons: React.ReactNode
  profileDetails: TimekeeperProfileInfo
  handleApprove: (row?: TimekeeperRow, all?: boolean) => Promise<void>
  handleReject: (row?: TimekeeperRow, all?: boolean) => void
  handleReset: (row: TimekeeperRow) => Promise<void>
  generalCardInfo?: KeyValuePairs[]
  diversityCardInfo?: DiversityKeyValuePairs[]
}

const settings = window.serverContext?.get('data_for_react')
const hasDiversityData = Boolean(settings?.collect_diversity_data)

const Rates = ({
  isNewTk,
  actionButtons,
  profileDetails,
  handleApprove,
  handleReject,
  handleReset,
  generalCardInfo,
  diversityCardInfo
}: Props) => {
  return (
    <section className={s.tabContainer}>
      {isNewTk ? (
        <p className={s.tabText}>
          Review the details below for this new timekeeper before approving.
        </p>
      ) : profileDetails.tkUpdates.length ? (
        <div className={s.profileUpdatesContainer}>
          <p className={s.profileUpdatesHeader}>Recent profile updates</p>
          <p className={s.profileUpdatesSubtitle}>Since last reviewed date</p>
          <div className={s.gridContainer}>
            <div className={s.gridHeader}>
              <p className={s.gridHeaderItem}>Fields</p>
              <p className={s.gridHeaderItem}>Old</p>
              <p className={s.gridHeaderItem}>Submitted</p>
            </div>
            <div className={s.gridBody}>
              {profileDetails.tkUpdates.map(p => (
                <>
                  <p className={s.gridBodyItem}>{formatAttribute(p.attribute)}</p>
                  <p className={s.fromValue}>
                    {p.from_value ? <span>{p.from_value}</span> : 'Not provided'}
                    <FaArrowRight />
                  </p>
                  <p className={s.toValue}>
                    {!p.to_value || p.to_value === '' ? 'Not provided' : p.to_value}
                  </p>
                </>
              ))}
            </div>
          </div>
        </div>
      ) : null}
      {profileDetails.pendingRates.length ? (
        <FlatTable
          title={
            !isNewTk
              ? `${pluralize('Pending Rate', profileDetails.pendingRates.length, true)}`
              : 'Pending Rates'
          }
          actions={actionButtons}
          columns={isNewTk ? NEW_TK_PENDING_RATES_COLUMNS : EXISTING_TK_PENDING_RATES_COLUMNS}
          rows={profileDetails.pendingRates as TimekeeperProfileDetailsRatesAPI[]}
          handleApprove={handleApprove}
          handleReject={handleReject}
          handleReset={handleReset}
        />
      ) : null}

      {!isNewTk ? (
        <p className={s.historyTableHeader}>Here are the historical rates for this timekeeper.</p>
      ) : null}
      {profileDetails.historicalRates.length ? (
        <FlatTable
          title={!isNewTk ? 'Standard Rates' : 'Historical Rates'}
          columns={isNewTk ? NEW_TIMEKEEPER_HISTORY_COLUMNS : EXISTING_TK_HISTORICAL_RATES_COLUMNS}
          rows={profileDetails.historicalRates as TimekeeperProfileDetailsRatesAPI[]}
          handleApprove={handleApprove}
          handleReject={handleReject}
          handleReset={handleReset}
        />
      ) : null}
      {isNewTk ? (
        <>
          <InfoCard info={generalCardInfo as KeyValuePairs[]} title="General Information" />
          {hasDiversityData && (
            <InfoCard
              info={diversityCardInfo as DiversityKeyValuePairs[]}
              title="Diversity Information"
            />
          )}
        </>
      ) : null}
    </section>
  )
}

export default Rates
