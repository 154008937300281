import s from './EnhancedTimekeeepersContainer.scss'
import { Cell, TimekeeperRow } from './types'
import { ReactNode, useMemo } from 'react'
import { formatDate } from './helpers'
import { AvatarList, ChatHover, Tooltip } from 'simple-core-ui'
import { StatusPopover } from 'timekeepers/common'

const commonStyle = { maxWidth: '400px', position: 'relative' }

const settings = window.serverContext?.get('data_for_react')
const hasDiversityData = settings?.collect_diversity_data

const determineStatusMode = (selectedTab: string, content: string, row: TimekeeperRow) => {
  if (selectedTab === 'pending') {
    return content === 'pending' ? 'buttons' : 'dropdown'
  }
  if (selectedTab === 'completed' && !row.is_rate_used) {
    return 'dropdown'
  }
  return 'readOnly'
}

const createColumn = (key: string, content: string, additionalProps = {}) => ({
  columnKey: key,
  content,
  isSortable: true,
  isFilterable: true,
  style: commonStyle,
  ...additionalProps
})

const renderFormattedDate = (cell: Cell) =>
  typeof cell.content === 'string' && cell.content && cell.content !== '---'
    ? formatDate(cell.content)
    : '---'

const renderLink = (
  cell: Cell,
  link?: string,
  clickCb?: (arg: unknown) => void,
  isNew?: boolean
) => {
  if (typeof cell.content === 'string' && cell.content !== '---') {
    return (
      <div className={s.timekeeperName}>
        {isNew ? <div className={s.badge}>New</div> : null}
        <a href={link} onClick={clickCb}>
          {cell.content}
        </a>
      </div>
    )
  } else return cell.content
}

const fieldMapping = {
  race: 'Race',
  gender: 'Gender',
  lgbtq: 'LGBTQ+',
  veteran_status: 'Veteran Status',
  disability: 'Disability'
}

const renderDiversityTooltip = (row: TimekeeperRow) => {
  const diversityKeys = Object.keys(fieldMapping) as Array<keyof typeof fieldMapping>

  const allFieldsCompleted = diversityKeys.every(key => row[key])

  if (allFieldsCompleted) {
    return (
      <div>
        All diversity fields for this <br />
        timekeeper are completed.
      </div>
    )
  } else {
    const providedFields = diversityKeys
      .filter(key => row[key])
      .map(key => fieldMapping[key])
      .sort()

    const notProvidedFields = diversityKeys
      .filter(key => !row[key])
      .map(key => fieldMapping[key])
      .sort()

    if (!providedFields.length) {
      return (
        <div>
          Diversity fields for this <br /> timekeeper are empty.
        </div>
      )
    }

    return (
      <>
        <div>
          <b>Not provided:</b> {notProvidedFields.length ? notProvidedFields.join(', ') : 'None'}
        </div>
        <div>
          <b>Provided:</b> {providedFields.length ? providedFields.join(', ') : 'None'}
        </div>
      </>
    )
  }
}

export const useColumns = (
  selectedTab: string,
  isListView: boolean,
  handleClickCell: (type: string, row: TimekeeperRow) => void,
  handleTimekeeperClick: (row: TimekeeperRow) => void,
  isBatchDetails: boolean,
  handleApprove: (row?: TimekeeperRow, all?: boolean) => Promise<void>,
  handleReject: (row?: TimekeeperRow, all?: boolean) => void,
  handleReset: (row: TimekeeperRow) => Promise<void>
) => {
  return useMemo(() => {
    const columns = []
    if (isListView) {
      columns.push(
        createColumn('timekeeper_id', 'ID'),
        createColumn('timekeeper', 'Timekeeper', {
          render: (cell: Cell, row: TimekeeperRow) =>
            renderLink(cell, undefined, () => handleTimekeeperClick(row), row.is_new_tk)
        }),
        createColumn('vendor_name', 'Vendor', {
          render: (cell: Cell, row: TimekeeperRow) =>
            renderLink(cell, `/invoices/vendors/${row.vendor_id}/?fromTimekeepers=true`)
        }),
        createColumn('classification', 'Classification'),
        createColumn('approved', 'Approved Rates', {
          render: (cell: Cell) => <div className={s.approved}>{cell.content as string}</div>
        }),
        createColumn('rejected', 'Rejected Rates', {
          render: (cell: Cell) => <div className={s.rejected}>{cell.content as string}</div>
        }),
        createColumn('pending', 'Pending Rates', {
          render: (cell: Cell) => <div className={s.pending}>{cell.content as string}</div>
        }),
        ...(hasDiversityData
          ? [
              createColumn('diversity', 'Diversity Filled', {
                render: (cell: Cell, row: TimekeeperRow) => (
                  <Tooltip
                    trigger={<div className={s.diversityCell}>{cell.content as string}</div>}
                    content={renderDiversityTooltip(row)}
                    placement="top"
                    contentClassName={s.diversityTooltip}
                    border
                    arrowStrokeColor="#D4D4D4"
                  />
                )
              })
            ]
          : []),
        createColumn('modified_date', 'Last Updated', { render: renderFormattedDate })
      )
    } else {
      const allTabColumns =
        selectedTab === 'all' || isBatchDetails
          ? [
              ...(!isBatchDetails ? [createColumn('id', 'ID')] : []),
              createColumn('timekeeper', 'Timekeeper', {
                render: (cell: Cell, row: TimekeeperRow) =>
                  renderLink(cell, undefined, () => handleTimekeeperClick(row), row.is_new_rate)
              }),
              ...(isBatchDetails
                ? [createColumn('id', 'ID')]
                : [
                    createColumn('vendor_name', 'Vendor', {
                      render: (cell: Cell, row: TimekeeperRow) =>
                        renderLink(cell, `/invoices/vendors/${row.vendor_id}/?fromTimekeepers=true`)
                    })
                  ]),
              createColumn('classification', 'Classification'),
              createColumn('requested_rate', 'Billed Rate'),
              createColumn('rack_rate', 'Rack Rate', {
                render: (cell: Cell) => cell.content || '---'
              }),
              createColumn('currency', `${isBatchDetails ? '' : 'Rate '}Currency`),
              ...(!isBatchDetails
                ? [
                    createColumn('status', 'Status', {
                      render: (cell: { content: string }) => (
                        <StatusPopover
                          mode="readOnly"
                          status={cell.content}
                          handleApprove={handleApprove}
                          handleReject={handleReject}
                          handleReset={handleReset}
                        />
                      )
                    })
                  ]
                : []),
              createColumn('effective', 'Effective Date', { render: renderFormattedDate }),
              ...(!isBatchDetails
                ? [createColumn('rejection_reason', 'Rejection Reason')]
                : [createColumn('type', 'Type')]),
              createColumn('reason_for_increase', 'Request Reason'),
              ...(isBatchDetails
                ? [
                    createColumn('status', '', {
                      render: (cell: Cell, row: TimekeeperRow) => {
                        return (
                          <StatusPopover
                            mode={determineStatusMode(selectedTab, cell.content as string, row)}
                            status={cell.content as string}
                            handleApprove={handleApprove}
                            handleReject={handleReject}
                            handleReset={handleReset}
                            tableRow={row}
                          />
                        )
                      },
                      isSortable: false,
                      ...(selectedTab === 'completed' ? { style: { float: 'right' } } : {})
                    })
                  ]
                : [])
            ]
          : []

      const pendingTabColumns =
        selectedTab !== 'all' && !isBatchDetails
          ? [
              createColumn('vendor_name', 'Vendor', {
                render: (cell: Cell, row: TimekeeperRow) =>
                  renderLink(cell, undefined, () => handleClickCell('batch_list', row))
              }),
              createColumn('pending_count', 'Timekeepers Rates'),
              createColumn('full_name', 'Submitted By'),
              createColumn('created_date', 'Received', { render: renderFormattedDate })
            ]
          : []

      const completedTabColumns =
        selectedTab === 'completed' && !isBatchDetails
          ? [
              createColumn('completed_date', 'Completed', { render: renderFormattedDate }),
              createColumn('approver_list', 'Reviewed', {
                render: (cell: Cell) =>
                  Array.isArray(cell.content) && cell.content.length ? (
                    <AvatarList
                      size={cell.content.length > 1 ? 'sm' : 'md'}
                      limit={2}
                      avatarStyles={{ marginLeft: 0 }}
                      entries={cell.content}
                    />
                  ) : (
                    '---'
                  ),
                style: { textAlign: 'center' }
              })
            ]
          : []

      columns.push(...allTabColumns, ...pendingTabColumns, ...completedTabColumns)
    }

    return columns
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab, isListView, isBatchDetails])
}

const createCell = (
  columnKey: string,
  content: ReactNode | string | Array<{ label: string }> | number = '---'
): Cell => ({
  columnKey,
  // @ts-expect-error
  content
})

const buildCells = (
  tk: TimekeeperRow,
  selectedTab: string,
  isListView: boolean,
  isBatchDetails: boolean
): Cell[] => {
  const baseCells = []

  if (isListView)
    baseCells.push(
      ...[
        createCell('timekeeper_id', tk.timekeeper_id),
        createCell('timekeeper', tk.timekeeper),
        createCell('vendor_name', tk.vendor_name),
        createCell('classification', tk.classification),
        createCell('approved', tk.approved),
        createCell('rejected', tk.rejected),
        createCell('pending', tk.pending),
        ...(hasDiversityData ? [createCell('diversity', tk.diversity)] : []),
        createCell('modified_date', tk.modified_date)
      ]
    )
  else
    baseCells.push(
      ...(selectedTab === 'all' || isBatchDetails
        ? [
            ...(!isBatchDetails ? [createCell('id', tk.timekeeper_id)] : []),
            createCell('timekeeper', tk.timekeeper)
          ]
        : []),
      ...(isBatchDetails
        ? [createCell('id', tk.timekeeper_id)]
        : [createCell('vendor_name', tk.vendor_name)]),
      ...(selectedTab === 'all' || isBatchDetails
        ? [
            createCell('classification', tk.classification),
            createCell('requested_rate', tk.requested_rate),
            createCell('rack_rate', tk.rack_rate),
            createCell('currency', tk.currency),
            ...(!isBatchDetails ? [createCell('status', tk.status)] : []),
            createCell('effective', tk.effective),
            ...(!isBatchDetails
              ? [
                  createCell(
                    'rejection_reason',
                    <div className={s.chatBubble}>
                      {tk.rejection_reason ? <ChatHover text={tk.rejection_reason} /> : '---'}
                    </div>
                  )
                ]
              : [createCell('type', tk.type || 'Standard')]),
            createCell(
              'reason_for_increase',
              <div className={s.chatBubble}>
                {tk.reason_for_increase ? <ChatHover text={tk.reason_for_increase} /> : '---'}
              </div>
            ),
            ...(isBatchDetails ? [createCell('status', tk.status)] : [])
          ]
        : []),
      ...(selectedTab !== 'all' && !isBatchDetails
        ? [
            createCell(
              'pending_count',
              selectedTab === 'pending'
                ? `${tk.pending_count || 0} out of ${tk.tk_count || 0} pending`
                : `${tk.approved_count ? `${tk.approved_count} approved` : ''}${
                    tk.approved_count && tk.rejected_count ? ', ' : ''
                  } ${tk.rejected_count ? `${tk.rejected_count} rejected` : ''}`
            ),
            createCell(
              'full_name',
              tk.full_name ? (
                <div>
                  <p>{tk.full_name}</p>
                  <p className={s.email}>{tk.email}</p>
                </div>
              ) : (
                '---'
              )
            ),
            createCell('created_date', tk.created_date)
          ]
        : []),
      ...(selectedTab === 'completed' && !isBatchDetails
        ? [
            createCell('completed_date', tk.completed_date),
            createCell('approver_list', tk.approver_list)
          ]
        : [])
    )

  return baseCells
}

export const useFilteredTimekeepers = (
  timekeepers: TimekeeperRow[],
  selectedTab: string,
  isListView: boolean,
  isBatchDetails: boolean
) => {
  return useMemo(() => {
    return timekeepers.map(tk => ({
      ...tk,
      id: tk.id || tk.timekeeper_file_id,
      cells: buildCells(tk, selectedTab, isListView, isBatchDetails)
    }))
  }, [timekeepers, selectedTab, isListView, isBatchDetails])
}

export const emailRateRow = (rates: any) => {
  return rates.map((rate: any) => {
    return {
      status_reason: rate.status_reason || null,
      requested_rate: rate.requested_rate,
      rack_rate: rate.rack_rate || '---',
      currency: rate.currency || '---',
      effective_date: formatDate(rate.effective) || '---',
      status: rate.status,
      type: 'Standard'
    }
  })
}
