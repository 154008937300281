import { PiCheckCircleFill, PiXCircleFill } from 'react-icons/pi'
import s from './StatusPopover.scss'
import { Popover } from 'simple-core-ui'
import { ReactNode } from 'react'
import { FaAngleDown } from 'react-icons/fa'
import { IoMdTime } from 'react-icons/io'
import { MdCheck } from 'react-icons/md'
import { TimekeeperProfileDetailsRatesAPI, TimekeeperRow } from 'timekeepers/types'
import cn from 'classnames'

const TOOLTIP_TEXT =
  'This timekeeper cannot be modified because it has been used on an invoice and/or has had subsequent updates submitted'

interface Props {
  mode: 'buttons' | 'dropdown' | 'readOnly'
  status: string
  handleApprove: (row?: TimekeeperRow, all?: boolean) => Promise<void>
  handleReject: (row?: TimekeeperRow, all?: boolean) => void
  handleReset: (row: TimekeeperRow) => Promise<void>
  tableRow?: TimekeeperRow | TimekeeperProfileDetailsRatesAPI
  readOnly?: boolean
}

const statusIcons: Record<string, ReactNode> = {
  approve: <PiCheckCircleFill color="#40A674" />,
  pending: <IoMdTime color="#0C56AE" />,
  reject: <PiXCircleFill color="#D3455B" />
}

const statusTexts: Record<string, string> = {
  approve: 'Approved',
  pending: 'Pending',
  reject: 'Rejected'
}

const renderStatus = (
  cell: { content: string },
  withTooltip?: boolean,
  isDropdown?: boolean,
  readOnly?: boolean
): JSX.Element => {
  const icon = statusIcons[cell.content]
  const text = statusTexts[cell.content]

  if (!icon || !text) return <span>---</span>

  return (
    <span className={s.status} data-tip={withTooltip ? TOOLTIP_TEXT : null}>
      {icon}
      <span>{text}</span>
      {isDropdown && !readOnly && <FaAngleDown className={s.dropDownIcon} />}
    </span>
  )
}

const renderPopover = (
  status: string,
  actions: {
    name: string
    text: string | ReactNode
    onClick: () => void
    className: string
  }[],
  readOnly?: boolean
) => (
  <Popover
    trigger={renderStatus({ content: status }, false, true, readOnly)}
    actions={actions}
    triggerClassName={cn({ [s.popoverTrigger]: !readOnly })}
  />
)

const renderStatusActions = (
  cellContent: string,
  row: TimekeeperRow,
  handleApprove: (row?: TimekeeperRow, all?: boolean) => Promise<void>,
  handleReject: (row?: TimekeeperRow, all?: boolean) => void,
  handleReset: (row: TimekeeperRow) => Promise<void>
) => [
  {
    name: 'APPROVE',
    text: (
      <span className={s.dropdownText}>
        {cellContent === 'approve' ? (
          <>
            <MdCheck />
            {'Approved'}
          </>
        ) : (
          'Approve'
        )}
      </span>
    ),
    onClick() {
      if (cellContent !== 'approve') handleApprove(row)
    },
    className: s.dropdownAction
  },
  {
    name: 'REJECT',
    text: (
      <span className={s.dropdownText}>
        {cellContent === 'reject' ? (
          <>
            <MdCheck />
            {'Rejected'}
          </>
        ) : (
          'Reject'
        )}
      </span>
    ),
    onClick() {
      if (cellContent !== 'reject') handleReject(row)
    },
    className: s.dropdownAction
  },
  {
    name: 'RESET',
    text: <span className={s.dropdownText}>Reset Status</span>,
    onClick() {
      handleReset(row)
    },
    className: s.dropdownAction
  }
]

const StatusPopover = ({
  mode,
  status,
  handleApprove,
  handleReject,
  handleReset,
  tableRow,
  readOnly
}: Props) => {
  return mode === 'buttons' ? (
    <div className={s.actionButtons}>
      <div onClick={() => handleApprove(tableRow as TimekeeperRow)}>
        <PiCheckCircleFill color="#40A674" />
      </div>
      <div onClick={() => handleReject(tableRow as TimekeeperRow)}>
        <PiXCircleFill color="#D3455B" />
      </div>
    </div>
  ) : mode === 'dropdown' ? (
    renderPopover(
      status,
      renderStatusActions(
        status,
        tableRow as TimekeeperRow,
        handleApprove,
        handleReject,
        handleReset
      ),
      readOnly
    )
  ) : (
    renderStatus({ content: status }, true)
  )
}

export default StatusPopover
