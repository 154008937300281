import { RootState } from 'configureStore'
import React, { createContext, useContext, useMemo } from 'react'
import { useSelector } from 'react-redux'

interface UserInfo {
  firstName: string
  lastName: string
  isCSM: boolean
}

interface AuthContextProps {
  user: UserInfo | null
}

const AuthContext = createContext<AuthContextProps>({ user: null })

export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const credentials = useSelector((state: RootState) => state.app.credentials)

  const authValue = useMemo(
    () => ({
      user: credentials?.user || null
    }),
    [credentials]
  )

  return <AuthContext.Provider value={authValue}>{children}</AuthContext.Provider>
}

export const useAuth = (): AuthContextProps => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider')
  }
  return context
}
